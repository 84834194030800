import { Container } from '@mui/material'
import { forwardRef } from 'react'
import { Card } from './styles'
import colors from 'constants/colors'
import { useModal } from 'services/modal/ModalProvider'

const ModalContent = forwardRef(({ children }, ref) => {
  const { severity } = useModal()
  let background = colors.white.main
  let borderColor = colors.sand.main
  switch (severity) {
    case 'success':
      background = colors.tertiary.main
      borderColor = colors.tertiary.main
      break

    default:
      background = colors.white.main
      borderColor = colors.sand.main
      break
  }

  return (
    <Container ref={ref} tabIndex={-1}>
      <Card sx={{ background, borderColor }}>{children}</Card>
    </Container>
  )
})

export default ModalContent
