import { Fade, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import { CustomSnackbarRoot } from './styles'
import { iconsSet } from './iconsSet'
import { fonts } from 'constants/fonts'

let textTimout = null

const CustomSnackbar = ({ isOpen, variant = 'success', timing = 1000, onClose, title }) => {
  const [text, setText] = useState('')
  const Icon = iconsSet[variant]?.icon

  const onCloseHandler = useCallback(() => {
    onClose && onClose()
    textTimout = setTimeout(() => setText(''), timing)
  }, [onClose, timing])

  useEffect(() => {
    const closeTimeout = setTimeout(onCloseHandler, 2000)
    return () => clearTimeout(closeTimeout)
  }, [isOpen, onClose, onCloseHandler])

  useEffect(() => {
    if (title) {
      clearTimeout(textTimout)
      setText(title)
    }
  }, [title])

  return (
    <Fade in={isOpen} timeout={timing} unmountOnExit>
      <CustomSnackbarRoot container justifyContent={'center'} onClick={onCloseHandler}>
        {Icon ? Icon : null}
        <Typography fontFamily={fonts.WalsheimTrial} fontWeight={500}>
          {text}
        </Typography>
      </CustomSnackbarRoot>
    </Fade>
  )
}

export default CustomSnackbar
