import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect
} from 'react'
import GlobalModal from '../../components/global-modal'

const defaultValue = {
  title: 'Oops!',
  content: 'text',
  severity: '',
  showCloseButton: false,
  openModal: () => null
}
const ModalContext = createContext(defaultValue)

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState()
  const [title, setTitle] = useState(defaultValue.title)
  const [content, setContent] = useState(defaultValue.controls)
  const [severity, setSeverity] = useState(defaultValue.severity)
  const [showCloseButton, setShowCloseButton] = useState(
    defaultValue.showCloseButton
  )

  const toggleModal = () => {
    setShowCloseButton(false)
    setSeverity('')
    setIsOpen(!isOpen)
  }

  const openModal = useCallback(
    ({
      title: newTitle,
      content: newContent,
      severity: newSeverity,
      showCloseButton: newShowCloseButton
    }) => {
      if (newTitle) setTitle(newTitle)
      if (newContent) setContent(newContent)
      if (newSeverity) setSeverity(newSeverity)
      if (newShowCloseButton) setShowCloseButton(newShowCloseButton)
      setIsOpen(true)
    },
    []
  )

  const value = useMemo(() => {
    return {
      openModal,
      isOpen,
      severity
    }
  }, [openModal, isOpen, severity])

  return (
    <ModalContext.Provider value={value}>
      <GlobalModal
        toggleModal={toggleModal}
        isOpen={isOpen}
        title={title}
        content={content}
        showCloseButton={showCloseButton}
      />
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = (onClose) => {
  const cxt = useContext(ModalContext)
  const { isOpen } = cxt

  useEffect(() => {
    return () => {
      if (isOpen) {
        onClose && onClose()
      }
    }
  }, [isOpen, onClose])

  return cxt
}
