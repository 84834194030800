import { useEffect, useMemo, useState } from 'react'
import { spotServiceHooks } from '../../services'
import { getCurrencySetting } from 'store/currency.state'
import { getPrices } from 'utils/helpers/prices'
import * as R from 'ramda'
import { formatCurrency } from 'utils/helpers/formatting'
import { useInactivity } from './inactivity.hook'
import { useModal } from 'services/modal/ModalProvider'
import { Grid, Typography } from '@mui/material'

const { useGetSpots } = spotServiceHooks

const INACTIVITY_THRESHOLD = process.env.REACT_APP_INACTIVITY_THRESHOLD

const spotWithPrices = (obj) => {
  const { id, weight, sign, additionalSign } = getCurrencySetting()
  const formatPrice = (price, weight) => {
    let priceData = weight !== 'lb' ? formatCurrency(price) : price
    return `${sign} ${priceData}${additionalSign}/${weight}`
  }

  const pricingData = getPrices(obj, id, obj.bags)
  pricingData.pricesFormatted = R.omit(
    ['tmp'],
    Object.entries(pricingData.prices).reduce((memo, [key, value]) => {
      return {
        ...memo,
        [key]: obj.bags >= key ? formatPrice(value, weight) : '-'
      }
    }, {})
  )
  return { ...obj, pricingData }
}

const transformSpots = R.applySpec({
  warehouse: R.head,
  origins: R.pipe(R.last, R.groupBy(R.prop('origin')))
})

const groupSpots = (filter) =>
  R.pipe(
    R.ifElse(
      () => filter !== '' && filter !== 'ALL',
      R.filter(R.pathEq(['warehouse', 'key'], filter)),
      R.identity
    ),
    R.groupBy(R.path(['warehouse', 'key'])),
    R.toPairs,
    R.map(transformSpots)
  )

export const useSpots = (deps) => {
  const [filter, setFilter] = useState('')
  const [bagsPrice, setBagsPrice] = useState(1)
  // Temporary exclusion: https://acato-nl.atlassian.net/browse/AS-13291

  const [getSpots, loading, spots, , abort] = useGetSpots(
    useMemo(
      () => ({
        memoize: false,
        onError: (err) => console.log('ErrorHandler:', err),
        onSuccess: (res) => {
          const spots = res.data.data
          const TEMP_EXCLUDE_AS13291 = [
            'PBO220056-01',
            'PBO230038-01',
            'BBO049320-01'
          ]
          let result = spots
            .map((spot) => spotWithPrices(spot))
            .map((spot) => ({
              ...spot,
              bags: `${spot.bags}`.match(/\./)
                ? formatCurrency(spot.bags, 2)
                : spot.bags
            }))
            .filter((spot) => spot.origin)
            .filter(({ batch }) => !TEMP_EXCLUDE_AS13291.includes(batch))

          return result
        }
      }),
      []
    )
  )

  const [inactive, restart] = useInactivity(INACTIVITY_THRESHOLD)
  const { openModal } = useModal(restart)

  useEffect(() => {
    if (inactive) {
      getSpots()
      openModal({
        title: 'Spotlist',
        content: (
          <Grid container>
            <Grid item>
              <Typography>
                Spotlist prices and inventory have been refreshed.
              </Typography>
            </Grid>
          </Grid>
        )
      })
    }
  }, [inactive, getSpots, openModal])

  const warehouses = useMemo(() => {
    if (!spots || !spots.length) return null
    return spots.reduce(
      (memo, curr) => {
        const { warehouse } = curr
        if (!memo.find((wh) => wh.key === warehouse.key)) {
          memo = [...memo, warehouse]
        }
        return memo
      },
      [{ key: 'ALL', title: 'All warehouses' }]
    )
  }, [spots])

  const filteredSpots = useMemo(() => {
    if (!spots || !spots.length) return null
    return groupSpots(filter)(spots)
  }, [spots, filter])

  const minMaxScores = useMemo(() => {
    if (!spots || !spots.length) return { min: 0, max: 0 }
    const min = Math.min(...spots.map((spot) => spot.score))
    const max = Math.max(...spots.map((spot) => spot.score))
    return { min, max }
  }, [spots])

  useEffect(() => {
    if (deps) getSpots()
    return () => abort()
  }, [deps, getSpots, abort])

  return {
    minMaxScores,
    spots: filteredSpots,
    setFilter,
    warehouses,
    loading,
    bagsPrice,
    setBagsPrice
  }
}
