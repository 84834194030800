import { useMemo, useState, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { Grid, Box } from '@mui/material'
import { useSpots } from './spots.hook'
import { CardLayout, TableLayout } from 'features/spot'
import AppBar from 'components/app-bar'
import { Main } from '../styles'
import { createCuppingScore } from 'components/cupping-score'
import SpotListDrawer from 'components/spotlist-drawer/SpotlistDrawer'
import { useSharedState } from 'store/ui.store'
import { WarehouseSelection } from 'features/spot/warehouse.select'
import { BagsPriceSelect } from 'features/spot/bagsPrice.select'
import { OriginWrapper, WarehouseWrapper, FilterDiv } from './styles'
import { LoadingIndicator } from 'components/loading-indicator/index'
import { ConnectedToggleView } from 'components/view-mode/connected.toggle.view'
import CartController from '../../components/cart-controller/CartController'
import { ToggleViewMode } from 'components/view-mode/toggle.view.mode'
import { useGoogleTagManager } from 'utils/helpers/gtm'
import { ExportSpotList } from 'features/spot/export.spots'

const Spotlist = () => {
  const getCuppingScoreRef = useRef(() => null)
  const viewmode = useSharedState('ui.viewmode')
  const { find_by_pbo } = useParams()

  const {
    spots,
    setFilter,
    minMaxScores,
    warehouses,
    loading,
    bagsPrice,
    setBagsPrice
  } = useSpots(viewmode)
  const [drawerData, setDrawerData] = useState(null)
  const { onSpotListItemOpen } = useGoogleTagManager()

  const onCardClickHandler = useCallback((data) => {
    setDrawerData(data)
    onSpotListItemOpen(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeDrawer = useCallback(() => {
    setDrawerData(null)
  }, [])

  const renderView = useMemo(() => {
    if (!spots || loading) return null
    const { min, max } = minMaxScores
    const cuppingScore = createCuppingScore(min, max)
    getCuppingScoreRef.current = cuppingScore

    return spots.map(({ warehouse, origins }, idx) => {
      const warehouseTitle = warehouses.find((wh) => wh.key === warehouse)?.title
      return (
        <div key={`warehouse-${idx}`}>
          <WarehouseWrapper>{warehouseTitle}</WarehouseWrapper>
          {Object.entries(origins).map(([origin, products], idx) => {
            if (find_by_pbo) {
              const drawerData = products.find(
                (product) => product.batch === find_by_pbo
              )
              if (drawerData) onCardClickHandler(drawerData)
            }

            return (
              <OriginWrapper key={`spot-${idx}`}>
                <Box mb={2}>
                  <Typography variant='h2'>{origin}</Typography>
                </Box>
                <Box>
                  <ToggleViewMode>
                    <Grid container spacing={2} view='grid'>
                      <CardLayout
                        spots={products}
                        bagsPrice={bagsPrice || 1}
                        cuppingScore={(score) => cuppingScore(score)}
                        onSpotClick={onCardClickHandler}
                      />
                    </Grid>
                    <TableLayout
                      view='table'
                      cuppingScore={(score) => cuppingScore(score)}
                      spots={products}
                      onSpotClick={onCardClickHandler}
                    />
                  </ToggleViewMode>
                </Box>
              </OriginWrapper>
            )
          })}
        </div>
      )
    })
  }, [
    onCardClickHandler,
    warehouses,
    minMaxScores,
    spots,
    bagsPrice,
    loading,
    find_by_pbo
  ])

  return (
    <>
      <AppBar title={'Spotlist'}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <ConnectedToggleView />
          </Grid>
          <Grid item>
            <CartController />
          </Grid>
        </Grid>
      </AppBar>
      <Main>
        <Box mb={5}>
          <LoadingIndicator isLoading={loading} label={'Loading spots'}>
            {warehouses && (
              <Grid
                container
                sx={{ alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Grid item xs='auto' sx={{ minWidth: '18rem' }}>
                  <FilterDiv container>
                    <WarehouseSelection options={warehouses} onChange={setFilter} />
                    {viewmode === 'grid' && (
                      <BagsPriceSelect value={bagsPrice} onChange={setBagsPrice} />
                    )}
                  </FilterDiv>
                </Grid>
                <Grid item xs='auto'>
                  <ExportSpotList />
                </Grid>
              </Grid>
            )}
            {renderView}
          </LoadingIndicator>
        </Box>
        <SpotListDrawer
          open={!!drawerData}
          onClose={closeDrawer}
          data={drawerData}
          cuppingScore={getCuppingScoreRef?.current}
        />
      </Main>
    </>
  )
}

export default Spotlist
