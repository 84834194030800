import { Grid, Modal, Typography } from '@mui/material'
import ModalContent from '../modal-content'
import ButtonRound from 'components/button-round'

// Icons
import CloseIcon from '@mui/icons-material/Close'

import { CloseButton } from './styles'

const GlobalModal = ({
  title,
  content,
  isOpen = false,
  toggleModal,
  showCloseButton = false
}) => {
  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContent>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <Typography variant={'h3'}>{title}</Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            {content}
          </Grid>
          {showCloseButton && (
            <ButtonRound variant='contained' color='secondary' onClick={toggleModal}>
              Close window
            </ButtonRound>
          )}
        </Grid>
        <CloseButton onClick={toggleModal}>
          <CloseIcon />
        </CloseButton>
      </ModalContent>
    </Modal>
  )
}

export default GlobalModal
