import { Card as MuiCard, styled } from '@mui/material'
import { colors } from '../../constants'

export const Card = styled(MuiCard)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxWidth: '100%',
  backgroundColor: colors.white.main,
  boxShadow: 24,
  padding: 30,
})
