import { Header } from 'components/spotlist-drawer/styles'
import { Grid, Typography } from '@mui/material'

const SpotListHeader = ({ title, origin, batch, regionWithGrade, score }) => {
  return (
    <Header>
      <Grid textAlign={'center'}>
        <Grid item xs={12}>
          <Typography variant='h1'>{title}</Typography>
        </Grid>
        <Grid item xs={12} mb={0.2}>
          <Typography>
            {origin} / {batch}
          </Typography>
        </Grid>
        <Grid item xs={12} mb={0.7}>
          <Typography>{regionWithGrade}</Typography>
        </Grid>
        <Grid item xs={12}>
          {score}
        </Grid>
      </Grid>
    </Header>
  )
}

export default SpotListHeader
