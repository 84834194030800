import React, { Fragment, useMemo } from 'react'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport
} from '@mui/x-data-grid'
import { GridWrapper, StatusIndicator } from './styles'
import { getCertifications } from 'constants/certification'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import TuneIcon from '@mui/icons-material/Tune'
import { Button, Grid } from '@mui/material'
import { parseDate } from 'utils/helpers/date'
import styles from './grid.styles.module.css'

const columns = (createCuppingScore) => [
  {
    field: 'origin',
    headerName: 'Origin',
    valueGetter: (params) => params.value,
    hide: true
  },
  {
    field: 'bags',
    headerName: 'Bags',
    width: 30,
    flex: 1,
    renderCell: (params) => {
      return (
        <div style={{ position: 'relative' }}>
          {params.row?.availability_date && <StatusIndicator />}
          {params.value}
        </div>
      )
    }
  },
  {
    field: 'batch',
    headerName: 'PBO',
    flex: 1,
    minWidth: 120
  },
  {
    field: 'location',
    flex: 1,
    minWidth: 150,
    headerName: 'Location',
    valueGetter: ({ row }) => {
      return row?.location?.name
    },
    renderCell: (params) => <strong>{params.value}</strong>,
    sortable: true
  },
  {
    field: 'region_with_grade',
    headerName: 'Region + grade',
    flex: 1,
    minWidth: 130
  },
  {
    field: 'process',
    headerName: 'Process',
    flex: 1,
    minWidth: 110
  },
  {
    field: 'certification',
    headerName: 'Certification',
    valueGetter: ({ row }) => {
      return getCertifications(row)
    },
    renderCell: (params) => (
      <span style={{ fontSize: '0.7rem' }}>{params.value}</span>
    ),
    flex: 1,
    minWidth: 120
  },
  {
    field: 'score',
    headerName: 'Cupping',
    renderCell: (params) => createCuppingScore(params.formattedValue),
    valueGetter: (params) => parseFloat(params.value).toFixed(2),
    flex: 1,
    minWidth: 70
  },
  {
    field: 'cupping_notes',
    headerName: 'Cupping Notes',
    renderCell: (params) =>
      params.value?.length > 0 ? params.value.join(', ') : '',
    valueGetter: (params) =>
      params.value?.length > 0 ? params.value.join(', ') : '',
    hide: true
  },
  {
    field: 'crop_year',
    headerName: 'Crop year',
    flex: 1,
    minWidth: 100
  },
  {
    field: 'pack',
    headerName: 'Packaging',
    valueGetter: ({ row }) => `${row?.pack_weight}${row?.pack_unit}`,
    flex: 1,
    minWidth: 100
  },
  ...Object.entries([1, 5, 10, 50, 150]).reduce((memo, [, val]) => {
    return [
      ...memo,
      {
        field: `price_${val}`,
        headerName: `Price ${val}+ bags`,
        flex: 1,
        minWidth: 100,
        valueGetter: ({ row }) => row.pricingData.pricesFormatted?.[`${val}`] || '-'
      }
    ]
  }, []),
  // {
  //   field: 'price',
  //   headerName: `Price ${selectedAmount}+ bags`,
  //   sortable: false,
  //   disableColumnMenu: true,
  //   valueGetter: ({ row }) => {
  //     console.log({ prices: row.pricingData })
  //     return row.pricingData.pricesFormatted[`${selectedAmount}`]
  //   },
  //   renderHeader: () => {
  //     const { weight, sign } = getCurrencySetting()
  //     return (
  //       <div style={{ minWidth: '80px' }}>
  //         <CustomSelect
  //           value={selectedAmount}
  //           transparent
  //           skipSelectBackground
  //           placeholder={`${sign}/${weight} per`}
  //           onChange={({ target: { value } }) => setSelectedAmount(value)}
  //           autoWidth
  //           size='xsmall'
  //           options={[
  //             { value: 1, label: '1+' },
  //             { value: 5, label: '5+' },
  //             { value: 10, label: '10+' },
  //             { value: 50, label: '50+' },
  //             { value: 150, label: '150+' }
  //           ]}
  //         />
  //       </div>
  //     )
  //   }
  // },
  {
    field: 'warehouse',
    headerName: 'Warehouse',
    valueGetter: (params) => params.value?.name || params.value?.title || '-',
    hide: true
  },
  {
    field: 'availability_date',
    headerName: 'Availability',
    width: 100,
    renderCell: (params) => {
      const date = params.value
      if (date) {
        return parseDate(params.value.replace(/ /g, 'T'), 'MMM YYYY')
      }
      return <CheckCircleRoundedIcon color='thyme1' />
    },
    valueFormatter: (params) => {
      const date = params.value
      if (date) {
        return parseDate(params.value.replace(/ /g, 'T'), 'MMM YYYY')
      }
      return 'In Stock'
    }
  },
  {
    field: 'addOrReserve',
    disableExport: true,
    headerName: '',
    minWidth: 100,
    disableColumnMenu: true,
    hideSortIcons: true,
    hideable: false,
    align: 'left',
    cellClassName: (params) => {
      return styles.sticky
    },
    renderCell: ({ row }) => {
      if (!row?.availability_date) {
        return (
          <Button variant='contained' color='primary' size='small'>
            Add
          </Button>
        )
      } else {
        return (
          <Button variant='contained' color='pine' size='small'>
            Reserve
          </Button>
        )
      }
    }
  }
]

const createToolbar = () => () => {
  return (
    <GridToolbarContainer>
      <Grid container spacing={2}>
        <Grid item>
          <GridToolbarExport
            sx={(theme) => ({
              px: 1.5,
              py: 0.5,
              backgroundColor: theme.palette.sand.main,
              color: theme.palette.black.main
            })}
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{ utf8WithBom: true, allColumns: true }}
          />
        </Grid>
        <Grid item>
          <GridToolbarColumnsButton
            startIcon={<TuneIcon />}
            sx={(theme) => ({
              px: 1.5,
              py: 0.5,
              backgroundColor: theme.palette.sand.main,
              color: theme.palette.black.main
            })}
          />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  )
}

export const TableLayout = ({ cuppingScore, spots, onSpotClick }) => {
  const cols = columns(cuppingScore)

  return useMemo(() => {
    if (!spots) return null

    return (
      <Fragment key={`group-${Math.random() * 100}`}>
        <div
          style={{
            flexGrow: 1,
            minHeight: 260,
            height: (spots.length + 4) * 52
          }}
        >
          <GridWrapper
            initialState={{
              sorting: {
                sortModel: [{ field: 'availability_date', sort: 'asc' }]
              }
            }}
            localeText={{ toolbarColumns: 'Manage columns' }}
            rowsPerPageOptions={[]}
            rowHeight={52}
            pageSize={100}
            rows={spots.map((prods, id) => ({ ...prods, id }))}
            columns={cols}
            onRowClick={(e) => {
              onSpotClick(e.row)
            }}
            components={{
              Toolbar: createToolbar()
            }}
            columnBuffer={20}
            disableSelectionOnClick
          />
        </div>
      </Fragment>
    )
  }, [spots, onSpotClick, cols])
}
