import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Grid, Tabs, Tab, Typography } from '@mui/material'
import ContentDrawer from 'components/content-drawer'
import SpotListHeader from './SpotlistHeader'
import ButtonRound from 'components/button-round'
import { NEW_LINE_CHARACTER } from 'constants/email'
import WithCartControllers from 'components/with-cart-controllers'
import { useCart, EnumCartItemTypes } from '../../utils/hooks/cart'
import { getCertifications } from 'constants/certification'
import { getCurrencySetting } from 'store/currency.state'
import { useUserData } from 'services/auth/index'

const defaultSpotData = {
  region_with_grade: '',
  batch: '',
  pack_weight: null,
  pack_weight_lb: null,
  pack_unit: 'KG',
  crop_year: null,
  screen_size: null,
  origin: 'Ethiopia',
  country: null,
  location: {
    name: null,
    content: null,
    url: null
  },
  other_certifications: 'NA',
  score: null,
  cupping_notes: [],
  is_sample_available: true,
  costs_usct: {},
  costs_eur: {},
  costs_gbp: {},
  costs_usd_kg: {},
  pricingData: {}
}

const SpotListDrawer = ({ onClose, open, cuppingScore, data, timing = 300 }) => {
  const [content, setContent] = useState(defaultSpotData)
  const { addToCart, addSample, availableQty } = useCart({
    reference: content?.batch,
    limit: data?.bags
  })
  const cartRef = useRef(null)
  const [tab, setTab] = useState(0)

  const onCloseHandler = useCallback(() => {
    onClose && onClose()
    setTimeout(() => setContent(defaultSpotData), timing)
  }, [onClose, timing])

  const {
    location,
    region_with_grade: regionWithGrade,
    score,
    batch,
    origin,
    crop_year: cropYear,
    screen_size: screenSize,
    pack_weight: packWeight,
    pack_unit: packUnit,
    packing_type,
    process: spotProcess,
    cupping_notes: cuppingNotes,
    availability_date,
    pricingData,
    is_sample_available
  } = content

  const { pricesFormatted: prices } = pricingData || { prices: {}, sign: '' }

  const { whoami, userData } = useUserData()

  const getLocalizedEmail = useMemo(() => {
    const defaultEmail = 'trabocca.marketing@trabocca.com'
    const USAEmail = 'mytrabocca-us@trabocca.com'

    if (userData?.addresses?.find((address) => address.country === 'US')) {
      return USAEmail
    } else {
      return defaultEmail
    }
  }, [userData])

  const spotSpec = [
    ['Origin', origin],
    ['Crop year', cropYear],
    ['Certification', getCertifications(content) || '-'],
    ['Screensize', screenSize, 4.4],
    ['Cupping score', score],
    ['Packing quantity', `${packWeight} ${packUnit}`],
    ['Packing type', packing_type]
  ]

  const onOrderSample = () => {
    addSample({
      value: content,
      onSuccess: () => {
        cartRef.current.openNotification('Sample added to cart')
      }
    })
  }

  const onSubmit = (values) => {
    addToCart({
      type: EnumCartItemTypes.Spots,
      value: content,
      amount: values.quantity,
      onSuccess: () =>
        cartRef.current.openNotification(`${values.quantity} items added to cart`)
    })
  }

  useEffect(() => {
    if (data) setContent(data)
  }, [data])

  useEffect(() => {
    whoami()
  }, [whoami])

  return (
    <ContentDrawer
      open={open}
      onClose={onCloseHandler}
      SlideProps={{ timeout: timing }}
    >
      <WithCartControllers
        ref={cartRef}
        onSubmit={onSubmit}
        availabilityDate={availability_date}
        max={availableQty}
      >
        <SpotListHeader
          title={location?.name}
          score={cuppingScore(score)}
          regionWithGrade={regionWithGrade}
          batch={batch}
          origin={origin}
        />
        <Tabs value={tab} onChange={(e, newVal) => setTab(newVal)}>
          <Tab label={'General'} />
          <Tab label={'Flavor'} />
        </Tabs>
        {tab === 0 ? (
          <Grid container p={2} pt={4}>
            <Grid item xs={12} mb={1}>
              <Typography variant={'h3'}>About</Typography>
            </Grid>
            <Grid item xs={12} mb={5}>
              <Typography>
                {<span dangerouslySetInnerHTML={{ __html: location.content }} />}
              </Typography>
            </Grid>
            {spotSpec.map((item, index) => (
              <Grid key={index} item xs={12} container mb={item[2] || 1.4}>
                <Grid item xs={6}>
                  <Typography variant={'body2'}>{item[0]}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={700}>{item[1]}</Typography>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} mb={1} pt={3}>
              <Typography variant={'h3'}>Process</Typography>
            </Grid>
            <Grid item xs={12} mb={5}>
              <Typography fontWeight={700}>{spotProcess}</Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              <Typography variant={'h3'}>
                Price per {getCurrencySetting()?.weight}
              </Typography>
            </Grid>
            {Object.keys(prices || {}).map((key) => {
              return (
                <Grid key={key} item xs={12} container mb={1.4}>
                  <Grid item xs={6}>
                    <Typography variant={'body2'}>{key}+</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={700}>{prices[key]}</Typography>
                  </Grid>
                </Grid>
              )
            })}
            <Grid item xs={12} pt={3} mb={1.5}>
              <ButtonRound
                href={`mailto:${getLocalizedEmail}?subject=Request for media&body=Request for media for:${NEW_LINE_CHARACTER} Coffee + Region and Grade: ${location?.name} / ${regionWithGrade}${NEW_LINE_CHARACTER}Process and PBO nr: ${spotProcess} / ${batch}`}
                variant={'outlined'}
                color={'secondary'}
                target={'_blank'}
                fullWidth
              >
                Request for media
              </ButtonRound>
            </Grid>
            <Grid item xs={12} mb={1.5}>
              <ButtonRound
                href={location.url}
                variant={'outlined'}
                color={'secondary'}
                target={'_blank'}
                fullWidth
              >
                Read more about {location?.name}
              </ButtonRound>
            </Grid>
            {is_sample_available && (
              <Grid item xs={12} mb={3}>
                <ButtonRound
                  variant={'outlined'}
                  color={'secondary'}
                  fullWidth
                  onClick={onOrderSample}
                >
                  Order a sample
                </ButtonRound>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container p={2} pt={4}>
            <Grid item xs={12} mb={1.5}>
              <Typography variant={'h3'}>Cupping notes</Typography>
            </Grid>
            <Grid container item xs={12} mb={1} component={'ul'} pl={3.3}>
              {cuppingNotes.map((note, index) => (
                <Grid key={index} item xs={12}>
                  <Typography component={'li'}>{note}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </WithCartControllers>
    </ContentDrawer>
  )
}

export default SpotListDrawer
