import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  minHeight: 358,
  padding: '20px',
  backgroundColor: theme.palette.sand.main
}))

export const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  lineHeight: 1.375
}))

export const Progress = styled(CircularProgress)(({ theme, color }) => ({
  position: 'absolute',
  svg: {
    circle: {
      stroke: color ? theme.palette[color].main : theme.palette.text.primary,
      strokeLinecap: 'round'
    }
  }
}))
